import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/react'
import SentryFullStory from '@sentry/fullstory'
import { Dedupe } from '@sentry/integrations/esm/dedupe'
import { RewriteFrames } from '@sentry/integrations/esm/rewriteframes'

const setAnalytics = () => {
  const devMode = import.meta.env.DEV
  const root = import.meta.env.BASE_URL
  const orgId = import.meta.env.VITE_FULLSTORY_ORG_ID as string
  const sentryOrg = import.meta.env.VITE_SENTRY_ORG_SLUG as string
  const dsn = import.meta.env.VITE_SENTRY_DSN as string

  FullStory.init({ orgId, devMode })

  Sentry.init({
    dsn,
    enabled: !devMode,
    normalizeDepth: 10,
    attachStacktrace: true,
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new RewriteFrames({ root }),
      new SentryFullStory(sentryOrg),
      new Dedupe(),
      Sentry.replayIntegration(),
      Sentry.replayCanvasIntegration() // enable canvas recording with Replay
    ],
  })
}

export default setAnalytics
